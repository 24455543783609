import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "./index.scss"
import LayoutIndex from "../components/LayoutIndex"
import Seo from "../components/seo"
import VideoGrid from "../components/videoGrid"
import Presentation from "../components/presentation"
import Contacts from "../components/contacts"
import { useLocation } from "react-router-dom"
import { SocialIcon } from "react-social-icons"
import BgVideoMP4 from "../images/bgpicaro.mp4"
import BgVideoWebM from "../images/bgpicaro.webm"
// import React, { useEffect } from "react";
// import GApageView from "../common/GApageView";
import ReactGA from "react-ga"
import create3DContent from "../three/create3DContent"

const googleAnalytics = (location) =>{
      ReactGA.initialize("G-G9SJF5L3WZ", {
      cookieDomain: "auto",
      debug: false,
    });
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);

}

const IndexPage = () => {
  const [isMobile, SetIsMobile] = useState(false)
  const logo = (
    <StaticImage
      src="../images/logo_white_2.png"
      width={300}
      quality={95}
      className="logoHeader"
      formats={["auto", "webp", "avif"]}
      alt="logo Picaro Video"
      style={{ marginBottom: `1.45rem` }}
    />
  )

  useEffect(() => {
    // create3DContent("threeCanvas")
    googleAnalytics(location)
  }, [])

  return (
    <LayoutIndex>
      <Seo
        title="Home"
        lang={"fr"}
        description={
          "Société de production audiovisuelle sur Paris et dans toute la France. Création audiovisuelle, captation événementielle, motion design et vues aériennes drone."
        }
      />
      
      {/* <div className="backgroundAnimation">
         <div
          id="threeCanvas"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1200,
            height: "100%",
          }}
        >
          
        </div>
         
      </div> */}
     


      <div className="body">
      <div className="videoBgWrapper">
        <video className="videoBg" autoPlay muted loop playsInline >
        <source src={BgVideoMP4} type="video/mp4" />
        <source src={BgVideoWebM} type="video/webm" />
        </video>
      </div> 

        <div className="wrapper-header-padding-bottom"></div>
        <div className="wrapper-header">
          <div className="App-header">
            <div className="headerTexts">
              <div className="logo">{logo}</div>
              <h1 className="h1">PICARO</h1>
              <h2 className="soustitre">CRÉATEUR DE VIDEO - PARIS</h2>
              {/* <h1 className="">DONNEZ VIE À VOTRE COMMUNICATION</h1> */}
              {/* <div className="soustitre">
                <h2 className="soustitre_2">Communication digitale - Création audiovisuelle</h2>
                <h2 className="soustitre_2">Motion Design - Vues Aériennes</h2>
              </div> */}
            </div>
          </div>
        </div> 
        <div id="Presentation"></div>
        <Presentation></Presentation>
        <div id="VideoGrid"><h1 className="sectionTitle">PORTFOLIO</h1></div>
        <VideoGrid></VideoGrid> 
        <div id="Contacts"><h1 className="sectionTitle">CONTACT</h1></div>
        <Contacts></Contacts> 
      </div>
      
    </LayoutIndex>

  )
}

export default IndexPage


// if (!this.state.isMobile && false) {
//   return (
//     <Grid container spacing={3}>
//       {this.state.types.map((e, i) => {
//         return (
//           <Grid key={i} item xs={3}>
//             <TypeButton
//               className={"typesBox"}
//               logo={
//                 "https://static.wixstatic.com/media/abebf0_b362d6aa65e64684bab61017a7d57945~mv2.png/v1/fill/w_280,h_280,al_c,q_85,usm_0.66_1.00_0.01/MARIANNES%20LOGO%20rond-03.webp"
//               }
//               title={e.title}
//               text={e.text}
//               onclick={""}
//             />
//           </Grid>
//         );
//       })}
//     </Grid>
//   );
// }
